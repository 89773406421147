<template>
  <div class="main-body">
    <div class="card-item">
      <div class="order-attention">
        <p>订单详情</p>
      </div>
      <a-divider />
      <a-row>
        <a-col :span="8">
          <div class="line-content">
            <span class="line-label">交易流水：</span>
            <span class="line-text">164724110676867411000</span>
          </div>
          <div class="line-content">
            <span class="line-label">订单编号：</span>
            <span class="line-text">202203141458741001</span>
          </div>
          <div class="line-content">
            <span class="line-label">订单类型：</span>
            <span class="line-text">普通订单</span>
          </div>
          <div class="line-content">
            <span class="line-label">订单来源：</span>
            <span class="line-text">微信小程序</span>
          </div>
          <div class="line-content">
            <span class="line-label">付款方式：</span>
            <span class="line-text">微信支付</span>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="line-content">
            <span class="line-label">买家：</span>
            <span class="line-text">哈哈</span>
          </div>
          <div class="line-content">
            <span class="line-label">配送方式：</span>
            <span class="line-text">物流配送</span>
          </div>
          <div class="line-content">
            <span class="line-label">收货人：</span>
            <span class="line-text">嗯嗯</span>
          </div>
          <div class="line-content">
            <span class="line-label">联系电话：</span>
            <span class="line-text">18656711111</span>
          </div>
          <div class="line-content">
            <span class="line-label">收货地址:</span>
            <span class="line-text">成都市武侯区下一站都市B314</span>
          </div>
        </a-col>
        <a-col :span="8">
          <div class="line-content">
            <span class="line-label">营销活动：</span>
            <span class="line-text">--</span>
          </div>
          <div class="line-content">
            <span class="line-label">买家留言：</span>
            <span class="line-text">--</span>
          </div>
          <div class="line-content">
            <span class="line-label">商家备注：</span>
            <span class="line-text">--</span>
          </div>
        </a-col>
      </a-row>
    </div>
    <div class="card-item">
      <div class="order-attention">
        <p>订单状态</p>
      </div>
      <a-divider />
      <div class="line-content">
        <span class="line-label">订单状态：</span>
        <span class="line-text danger">已关闭</span>
        <a-button type="primary" size="small" class="note">备注</a-button>
      </div>
      <div class="status-note">
        <div class="status-label">提醒：</div>
        <div class="status-content">
          <p>买家付款成功后，货款将直接进入您的商户号（微信、支付宝）</p>
          <p>请及时关注你发出的包裹状态，确保可以配送至买家手中</p>
          <p>如果买家表示没收到货或货物有问题，请及时联系买家处理，友好协商</p>
        </div>
      </div>
    </div>
    <div class="card-item">
      <div class="order-attention">
        <p>商品信息</p>
      </div>
      <a-divider />
      <a-table
        :columns="columns"
        :data-source="data"
        bordered
        :pagination="false"
      >
        <template #bodyCell="{ column, text }">
          <template v-if="column.dataIndex === 'name'">
            <a href="javascript:;">{{ text }}</a>
          </template>
        </template>
      </a-table>
    </div>
    <div class="card-item">
      <div class="order-attention">
        <p>操作日志</p>
      </div>
      <a-divider />
      <a-timeline>
        <a-timeline-item
          >买家收货
          <span class="action-time">2022-03-14 14:59:17</span></a-timeline-item
        >
        <a-timeline-item
          >商家发货<span class="action-time"
            >2022-03-14 14:59:17</span
          ></a-timeline-item
        >
        <a-timeline-item
          >买家【张三】支付了订单<span class="action-time"
            >2022-03-14 14:59:17</span
          ></a-timeline-item
        >
        <a-timeline-item
          >买家【张三】下单了<span class="action-time"
            >2022-03-14 14:59:17</span
          ></a-timeline-item
        >
        <a-timeline-item
          >买家【张三】下单了<span class="action-time"
            >2022-03-14 14:59:17</span
          ></a-timeline-item
        >
        <a-timeline-item
          >买家【张三】下单了<span class="action-time"
            >2022-03-14 14:59:17</span
          ></a-timeline-item
        >
        <a-timeline-item
          >买家【张三】下单了<span class="action-time"
            >2022-03-14 14:59:17</span
          ></a-timeline-item
        >
      </a-timeline>
    </div>
  </div>
</template>
<script>
import { defineComponent } from "vue";

const data = [
  {
    goods_name: "北欧大型落地装饰摆件盆景",
    price: "200.00",
    number: "1",
    count_price: "200.00",
    refund_status: "维权结束",
    status: "未发货",
  },
  {
    goods_name: "北欧大型落地装饰摆件盆景",
    price: "200.00",
    number: "1",
    count_price: "200.00",
    refund_status: "维权结束",
    status: "未发货",
  },
  {
    goods_name: "北欧大型落地装饰摆件盆景",
    price: "200.00",
    number: "1",
    count_price: "200.00",
    refund_status: "维权结束",
    status: "未发货",
  },
];
export default defineComponent({
  setup() {
    const columns = [
      {
        title: "商品信息",
        dataIndex: "goods_name",
        key: "goods_name",
      },
      {
        title: "价格",
        dataIndex: "price",
        key: "price",
      },
      {
        title: "数量",
        dataIndex: "number",
        key: "number",
      },
      {
        title: "小计（元）",
        dataIndex: "count_price",
        key: "count_price",
      },
      {
        title: "退款状态",
        dataIndex: "refund_status",
        key: "refund_status",
      },
      {
        title: "状态",
        dataIndex: "status",
        key: "status",
      },
    ];
    return {
      data,
      columns,
    };
  },
});
</script>
<style scoped lang="less">
.card-item {
  margin-bottom: 50px;
  .note {
    margin-left: 16px;
  }
  .status-note {
    display: flex;
    .status-label {
      width: 50px !important;
      color: #ff4d4f;
    }
    .status-content {
      min-width: 400px;
      color: #a4a4a4;
    }
  }
  .action-time {
    color: #b2b2b2;
    margin-left: 20px;
  }
}
</style>